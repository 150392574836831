<template>
    <div class="row">
        <div class="col-12 col-md-2">
            <div class="form-group">
                <label>
                    {{ $t('REQUESTS.BUILDING.COUNTY') }} *
                </label>
                <b-form-select
                    v-model="cadastral.county"
                    :options="counties"
                    :ref="county"
                    :name="county"
                    value-field="county"
                    text-field="county"
                >
                </b-form-select>
            </div>
        </div>
        <div class="col-12 col-md-2">
            <div class="form-group">
                <label>
                    {{ $t('REQUESTS.BUILDING.LOCALITY') }} *
                </label>
                <b-form-select
                    v-model="cadastral.locality"
                    :options="localities"
                    :ref="locality"
                    :name="locality"
                    value-field="name"
                    text-field="name"
                    :disabled= "!localities.length > 0"
                >
                </b-form-select>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="form-group">
                <label>
                    {{ $t('REQUESTS.BUILDING.CADASTRAL_NUMBER') }} *
                </label>
                <div class="row">
                    <div class="col">
                        <st-input-text
                            v-model="cadastral.cadastral_number"
                            :ref="cadastralNumber"
                            :name="cadastralNumber"
                        />
                    </div>
                    <div class="col">
                        <slot/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';

    export default {
        name: 'CadastralNumberForm',
        props: {
            cadastral: {
                type: Object,
            },
            index: {
                type: Number,
            },
            formValidation: {
                type: Object,
            },
        },
        data() {
            return {
                localities: [],
            }
        },
        computed: {
            ...mapState({
                counties: state => state.shared.counties,
            }),
            ...mapGetters({
                appConfig: 'shared/appConfig',
            }),
            county() {
                return `county${this.index}`;
            },
            locality() {
                return `locality${this.index}`;
            },
            cadastralNumber() {
                return `cadastral_number${this.index}`;
            },
        },
        mounted() {
            this.cadastral.county = this.appConfig.DEFAULT_COUNTY;

            const notEmpty = {
                message: this.$t('VALIDATION.REQUIRED_FIELD'),
            };

            this.formValidation.addField(this.county, {
                validators: {
                    notEmpty,
                },
            });

            this.formValidation.addField(this.locality, {
                validators: {
                    notEmpty,
                },
            });

            this.formValidation.addField(this.cadastralNumber, {
                validators: {
                    notEmpty,
                    numeric: {
                        message: this.$t('VALIDATION.NUMERIC_FIELD'),
                    },
                },
            });
        },
        beforeDestroy() {
            this.formValidation.removeField(this.county);
            this.formValidation.removeField(this.locality);
            this.formValidation.removeField(this.cadastralNumber);
        },
        methods: {
            ...mapActions({
                getLocalities: 'profile/getLocalities',
            }),
        },
        watch: {
            'cadastral.county': {
                deep: true,
                immediate: true,
                handler(countyValue, oldCountyValue) {
                    this.cadastral.locality = '';

                    if (countyValue) {
                        const countyParams = this.counties.find( county => county.county === countyValue);

                        this.getLocalities({parent_id: countyParams.id})
                            .then((res)=> {
                                this.localities = res;
                            })
                            .catch((error)=> {
                                console.log('error', error);
                                this.$notify({
                                    type: 'error',
                                    message: this.$t('SET_PROFILE_DATA.ERRORS.LOCALITIES'),
                                });
                            });
                    }
                },
            },
        },
    }
</script>
