<template>
    <form class="form" id="st-building-details-land-form" novalidate="novalidate">
        <div class="card">
            <!--begin::Card Header-->
            <div class="card-header border-0">
                <div class="row align-items-baseline">
                    <div class="col-12 col-md-2">
                        <h6>
                            {{ $t('REQUESTS.BUILDING.DETAILS') }}
                        </h6>
                    </div>
                    <div class="col-12 col-md-1">
                        <b-form-radio
                            v-model="selected"
                            value="land"
                            size="lg"
                        >
                            {{ $t('REQUESTS.BUILDING.LAND') }}
                        </b-form-radio>
                    </div>
                    <div class="col-12 col-md-9">
                        <b-form-radio
                            v-model="selected"
                            value="land_constructions"
                            size="lg"
                        >
                            {{ $t('REQUESTS.BUILDING.LAND_CONSTRUCTIONS') }}
                        </b-form-radio>
                    </div>
                </div>
            </div>
            <!--end::Card Header-->
            <!--begin::Card Body-->
            <div class="card-body pt-0">
                <!--begin::Content-->
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label>
                                {{ $t('REQUESTS.BUILDING.COUNTY') }} *
                            </label>
                            <div label="">
                                <b-form-select
                                    v-model="buildingDetails.county"
                                    :options="counties"
                                    ref="county"
                                    name="county"
                                    value-field="county"
                                    text-field="county"
                                >
                                </b-form-select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t('REQUESTS.BUILDING.LOCALITY') }} *
                            </label>
                            <div label="">
                                <b-form-select
                                    v-model="buildingDetails.locality"
                                    :options="localities"
                                    ref="locality"
                                    name="locality"
                                    value-field="name"
                                    text-field="name"
                                    :disabled= "!localities.length > 0"
                                >
                                </b-form-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <div class="form-group">
                                    <label>
                                        {{ $t('REQUESTS.BUILDING.VILLAGE') }}
                                    </label>
                                    <div label="">
                                        <b-form-select
                                            v-model="buildingDetails.village"
                                            :options="villages"
                                            value-field="name"
                                            text-field="name"
                                            ref="village"
                                            name="village"
                                            :disabled= "!villages.length > 0"
                                        ></b-form-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="form-group">
                                    <label>
                                        {{ $t('REQUESTS.BUILDING.SECTOR') }}
                                    </label>
                                    <div label="">
                                        <st-input-text
                                            v-model="buildingDetails.sector"
                                            ref="sector"
                                            name="sector"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label>
                                {{ $t('REQUESTS.BUILDING.STREET') }}
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="buildingDetails.street"
                                    ref="street"
                                    name="street"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <div class="form-group">
                                    <label>
                                        {{ $t('REQUESTS.BUILDING.POSTAL_CODE') }}
                                    </label>
                                    <div label="">
                                        <st-input-text
                                            v-model="buildingDetails.postal_code"
                                            ref="postal-code"
                                            name="postal_code"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="form-group">
                                    <label>
                                        {{ $t('REQUESTS.BUILDING.NUMBER') }}
                                    </label>
                                    <div label="">
                                        <st-input-text
                                            v-model="buildingDetails.street_number"
                                            ref="street-number"
                                            name="street_number"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <div class="form-group">
                                    <label>
                                        {{ $t('REQUESTS.BUILDING.STAIRCASE') }}
                                    </label>
                                    <div label="">
                                        <st-input-text
                                            v-model="buildingDetails.staircase"
                                            ref="staircase"
                                            name="staircase"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm col-sm-offset-2">
                                <div class="form-group">
                                    <label>
                                        {{ $t('REQUESTS.BUILDING.BLOCK') }}
                                    </label>
                                    <div label="">
                                        <st-input-text
                                            v-model="buildingDetails.block"
                                            ref="block"
                                            name="block"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="row">
                            <div class="col-sm">
                                <div class="form-group">
                                    <label>
                                        {{ $t('REQUESTS.BUILDING.FLOOR') }}
                                    </label>
                                    <div label="">
                                        <st-input-text
                                            v-model="buildingDetails.floor"
                                            ref="floor"
                                            name="floor"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="form-group">
                                    <label>
                                        {{ $t('REQUESTS.BUILDING.APARTMENT') }}
                                    </label>
                                    <div label="">
                                        <st-input-text
                                            v-model="buildingDetails.apartment"
                                            ref="apartment"
                                            name="apartment"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t('REQUESTS.BUILDING.LAND_AREA') }} *
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="buildingDetails.land_area"
                                    ref="land-area"
                                    name="land_area"
                                    :placeholder="$t('PLACEHOLDERS.MP')"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ $t('REQUESTS.BUILDING.OTHER_IDENTIFICATION') }}
                            </label>
                            <div label="">
                                <b-textarea
                                    v-model="buildingDetails.other_identification"
                                    ref="other-identification"
                                    name="other_identification"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row"
                            v-for="(cadastral, index) in buildingDetails.cadastrals" :key="index" :ref="index"
                        >
                            <div v-if="cadastral" class="col">
                                <cadastral-number-form
                                    :cadastral="cadastral"
                                    :key="index"
                                    :index="index"
                                    :formValidation="fv"
                                >
                                    <st-button
                                        v-if="enableRemoveCadastral"
                                        size="large"
                                        variant="link"
                                        :callback="() => removeCadastralNumber(index)"
                                    >
                                        <i class="fas fa-minus text-danger"></i>
                                    </st-button>
                                </cadastral-number-form>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <st-button
                        size="large"
                        variant="link"
                        :callback="addCadastralNumber"
                    >
                        <i class="fas fa-plus text-primary"></i>
                        {{ $t('REQUESTS.BUILDING.ADD_CADASTRAL_NUMBER')}}
                    </st-button>
                </div>
                <!--end::Content-->
            </div>
            <!--end::Card Body-->
            <st-button :callback="validateForm" variant="danger">validate form</st-button>
        </div>
    </form>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';

    import KTUtil from '@/assets/js/components/util';
    import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

    // FormValidation plugins
    import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
    import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';

    import CadastralNumberForm from '@/modules/requests/components/CadastralNumberForm';

    export default {
        name: 'BuildingDetails',
        components: {
            CadastralNumberForm,
        },
        data() {
            return {
                buildingDetails: {
                    county: '',
                    locality: '',
                    village: '',
                    sector: '',
                    street: '',
                    postal_code: '',
                    street_number: '',
                    staircase: '',
                    block: '',
                    floor: '',
                    apartment: '',
                    land_area: '',
                    other_identification: '',
                    cadastrals: [],
                },
                selected: 'land',
                localities: [],
                villages: [],
            };
        },
        computed: {
            ...mapState({
                counties: state => state.shared.counties,
                validationError: state => state.requests.buildingDetails.validationError,
            }),
            ...mapGetters({
                appConfig: 'shared/appConfig',
            }),
            enableRemoveCadastral() {
                return this.buildingDetails.cadastrals
                    .filter(cadastral => cadastral)
                    .length > 1;
            }
        },
        methods: {
            ...mapActions({
                updateBuildingDetailsError: 'requests/updateBuildingDetailsError',
                getLocalities: 'profile/getLocalities',
                getVillages: 'profile/getVillages',
            }),
            addCadastralNumber() {
                this.buildingDetails.cadastrals.push({
                    county: '',
                    locality: '',
                    cadastral_number: '',
                });
            },
            removeCadastralNumber(index) {
                this.$set(this.buildingDetails.cadastrals, index, undefined);
            },
            validateForm() {
                this.fv.validate()
                    .then(status => {
                        // TO DO: Remove console.log
                        console.log(status, this.buildingDetails);
                    });
            },
        },
        watch: {
            'buildingDetails.county': {
                deep: true,
                immediate: true,
                handler(countyValue, oldCountyValue) {
                    this.buildingDetails.locality = '';

                    if (countyValue) {
                        const countyParams = this.counties.find( county => county.county === countyValue);

                        this.getLocalities({parent_id: countyParams.id})
                            .then((res)=> {
                                this.localities = res;
                            })
                            .catch((error)=> {
                                console.log('error', error);
                                this.$notify({
                                    type: 'error',
                                    message: this.$t('SET_PROFILE_DATA.ERRORS.LOCALITIES'),
                                });
                            });
                    }
                },
            },
            'buildingDetails.locality': {
                deep: true,
                immediate: true,
                handler(newValue, oldValue) {
                    this.buildingDetails.village = '';

                    if (newValue) {
                        const localityParams = this.localities.find(locality => locality.name === newValue);

                        if (localityParams.search_villages)  {
                            this.getVillages({parent_id: localityParams.search_villages})
                            .then((res)=> {
                                this.villages = res;
                            })
                            .catch(() => {
                                this.$notify({
                                    type: 'error',
                                    message: this.$t('SET_PROFILE_DATA.ERRORS.VILLAGES'),
                                });
                            })
                        } else {
                            this.villages = [];
                        }
                    } else {
                        this.villages = [];
                    }
                },
            },
            villages: {
                deep: true,
                immediate: true,
                handler(newValue, oldValue) {
                    console.log(newValue);
                    if (this.fv) {
                        if (newValue.length > 0) {
                            this.fv.addField('village', {
                                validators: {
                                    notEmpty: {
                                        message: this.$t('VALIDATION.REQUIRED_FIELD'),
                                    }
                                }
                            });
                        } else {
                            this.fv.removeField('village');
                        }
                    }
                },
            }
        },
        mounted() {
            this.buildingDetails.county = this.appConfig.DEFAULT_COUNTY;

            // TO DO: extract validators to be exported in other components
            const notEmpty = {
                message: this.$t('VALIDATION.REQUIRED_FIELD'),
            };

            const form = KTUtil.getById('st-building-details-land-form');
            this.fv = formValidation(form, {
                fields: {
                    county: {
                        validators: {
                            notEmpty,
                        },
                    },
                    locality: {
                        validators: {
                            notEmpty,
                        },
                    },
                    land_area: {
                        validators: {
                            notEmpty,
                            numeric: {
                                message: this.$t('VALIDATION.NUMERIC_FIELD'),
                            },
                        },
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    bootstrap: new Bootstrap(),
                },
            });

            this.addCadastralNumber();
        },
    }
</script>
